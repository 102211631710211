import Adsense from './ads/Adsense.js'
import InArticleAdsense from './ads/InArticleAdsense.js'
import InFeedAdsense from './ads/InFeedAdsense.js'
import AutoAdsense from './ads/AutoAdsense.js'

export default {
  Adsense,
  InArticleAdsense,
  InFeedAdsense,
  AutoAdsense
}
